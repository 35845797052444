<template>
  <div class="home">
    <h1>Vue Books PWA</h1>
    <h2>A simple progressive web application</h2>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Home extends Vue {}
</script>