





























import Vue from "vue";

export default class App extends Vue {
  title = "Vue Books";
}
